import "./styles.scss"

import React from "react"

const Schema = () => {
  return (
    <section className="how-schema">
      <div className="container-fluid">
        <h2>Uczestnicy projektu</h2>

        <div className="how-schema__table">
          <div className="how-schema__table-row">
            <div className="row">
              <div className="col-md-4">
                <h4>Inwestor</h4>
              </div>
              <div className="col-md-8">
                <p>
                  Udzielając pożyczki inwestycyjnej stajesz się inwestorem
                  projektu deweloperskiego.
                </p>
              </div>
            </div>
          </div>
          <div className="how-schema__table-row">
            <div className="row">
              <div className="col-md-4">
                <h4>Trust Investment Projekt 15 sp. z o.o.</h4>
              </div>
              <div className="col-md-8">
                <p>Spółka celowa powołana do realizacji inwestycji.</p>
              </div>
            </div>
          </div>
          <div className="how-schema__table-row">
            <div className="row">
              <div className="col-md-4">
                <h4>Trust Investment S.A.</h4>
              </div>
              <div className="col-md-8">
                <p>
                  Udziałowiec większościowy posiadający większość udziałów,
                  odpowiedzialny za nadzór nad całością procesu budowy oraz za
                  sprzedaż inwestycji.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="how-schema__image">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <img
                src={
                  require("assets/images/how/how-schema-image-15.svg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <div className="how-schema__chart">
          <div className="how-schema__chart-header">
            <h4>Opcje PUT/CALL</h4>
            <p>
              Obligatoryjny odkup udziałów z gwarantowanym dla Inwestora
              (Ciebie) <strong>zyskiem</strong>
            </p>
          </div>
          <div className="how-schema__chart-row">
            <div>
              <div className="how-schema__chart-circle how-schema__chart-circle--first">
                <img
                  src={require("assets/images/how/how-schema-logo.svg").default}
                  alt=""
                />
              </div>
              <p>
                Nadzór nad realizacją i sprzedażą inwestycji realizowanej przez
                spółkę celową
              </p>
            </div>
            <div>
              <div className="how-schema__chart-circle how-schema__chart-circle--gray">
                Spółka celowa
              </div>
              <p>
                Powołana do przeprowadzenia realizacji projektu deweloperskiego
                - to w niej nabywasz udziały.
              </p>
            </div>
            <div>
              <div className="how-schema__chart-circle how-schema__chart-circle--last">
                Inwestor (Ty)
              </div>
              <p>
                Nabywa udziały w spółce
                <br /> celowej wraz z gwarancją <strong>zysku.</strong>
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default Schema
