import "./styles.scss"

import React from "react"

const Baner = () => {
  return (
    <section className="how-baner">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <h4>
              Uruchamiając realizację nowego projektu deweloperskiego zapraszamy
              Cię do udziału w jego realizacji i partycypowania w zyskach
              wynikających z marży deweloperskiej. Proponowany przez nas projekt
              inwestycyjny polega na udzieleniu przez Ciebie pożyczki
              inwestycyjnej dla Trust Investment Projekt 15 - spółce
              deweloperskiej będącej bezpośrednim właścicielem i realizatorem
              projektu. Tym samym staniesz się inwestorem w tym projekcie,
              otrzymując wypłatę odsetek w okresach kwatralnych. Po upływie 24
              miesięcy od podpisania umowy kapitał zostanie zwrócony, wraz z
              ostatnią wypłatą należnych odsetek. Warunki udziału w Inwestycji w
              tym sposób ustalania odsetek został określony w umowie.
            </h4>
          </div>
          <div className="col-md-5 offset-md-1">
            <p>
              Projekt deweloperski będzie nadzorowany przez spółkę z grupy Trust
              - Trust Investment SA, dewelopera o ugruntowanej pozycji,
              skupiającego się na dwóch gałęziach obszernego rynku nieruchomości
              - inwestycjach mieszkaniowych oraz komercyjnych. Podmioty
              uczestniczące w projekcie posiadają szerokie doświadczenie w
              realizacji inwestycji i know how pozwalające im na sprawną
              realizację projektu. Z kolei zawierana z Inwestorami umowa określa
              przejrzyste warunki uczestnictwa w projekcie.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Baner
