import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import Ico1 from "assets/images/how/how-process-ico-1.svg"
import Ico2 from "assets/images/how/how-process-ico-2.svg"
import Ico3 from "assets/images/how/how-process-ico-3.svg"
import Ico4 from "assets/images/how/how-process-ico-4.svg"
import Ico5 from "assets/images/how/how-process-ico-5.svg"
import Ico6 from "assets/images/how/how-process-ico-6.svg"

const Process = () => {
  return (
    <section className="how-process">
      <div className="container-fluid">
        <h2>Jak wygląda proces inwestycyjny?</h2>

        <div className="how-process__item">
          <div className="row align-items-center">
            <div className="col-md-1">
              <span>1</span>
            </div>
            <div className="col-md-2">
              <div className="how-process__item-image">
                <img src={Ico1} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <h4>
                Prezentujemy nowy projekt inwestycyjny, który będziemy
                realizować.
              </h4>
            </div>
            <div className="col-md-5">
              <p>
                Z projektem można się zapoznać tutaj:{" "}
                <Link to="/projekt-15/">trustfinances.pl/projekt-15</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="how-process__item">
          <div className="row align-items-center">
            <div className="col-md-1">
              <span>2</span>
            </div>
            <div className="col-md-2">
              <div className="how-process__item-image">
                <img src={Ico2} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <h4>Kontaktujesz się z nami:</h4>
            </div>
            <div className="col-md-5">
              <a href="tel:+48 600 500 560">a) +48 600 500 560</a>
              <a href="mailto:office@trustfinances.pl">
                b) office@trustfinances.pl
              </a>
              <Link to="/kontakt/">c) www.trustfinances.pl/kontakt</Link>
            </div>
          </div>
        </div>

        <div className="how-process__item">
          <div className="row align-items-center">
            <div className="col-md-1">
              <span>3</span>
            </div>
            <div className="col-md-2">
              <div className="how-process__item-image">
                <img src={Ico3} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <h4>Przedstawiamy projekt oraz warunki Umowy</h4>
            </div>
          </div>
        </div>

        <div className="how-process__item">
          <div className="row align-items-center">
            <div className="col-md-1">
              <span>4</span>
            </div>
            <div className="col-md-2">
              <div className="how-process__item-image">
                <img src={Ico4} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <h4>Podejmujemy współpracę:</h4>
            </div>
            <div className="col-md-5">
              <p>Proponujemy aby pierwszym krokiem było:</p>
              <p>a) zawieramy umowę inwestycyjną</p>
              <p>b) stajesz się inwestorem w spółce deweloperskiej</p>
            </div>
          </div>
        </div>

        <div className="how-process__item">
          <div className="row align-items-center">
            <div className="col-md-1">
              <span>5</span>
            </div>
            <div className="col-md-2">
              <div className="how-process__item-image">
                <img src={Ico5} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <h4>Realizujemy projekt deweloperski:</h4>
            </div>
            <div className="col-md-5">
              <p>a) otrzymujesz raporty postępów budowy</p>
              <p>b) otrzymujesz raporty sprzedaży mieszkań</p>
              <p>
                c)Twój zysk wypłacamy co kwartał na wskazany w Umowie rachunek
                bankowy
              </p>
            </div>
          </div>
        </div>

        <div className="how-process__item">
          <div className="row align-items-center">
            <div className="col-md-1">
              <span>6</span>
            </div>
            <div className="col-md-2">
              <div className="how-process__item-image">
                <img src={Ico6} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <h4>Zamknięcie projektu:</h4>
            </div>
            <div className="col-md-5">
              <p>a) zwracamy wniesiony kapitał</p>
              <p>
                b) przelew ostatniej transzy zysku oraz kwoty wpłaconego
                kapitału, na wskazany w Umowie rachunek bankowy
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Process
