import React from "react"

const Worth = () => {
  return (
    <section className="how-worth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            <h2>Dlaczego warto?</h2>
          </div>
          <div className="col-md-7">
            <div className="how-worth__list">
              <p>Znany od początku sposób wyliczenia zysku</p>
              <p>Inwestycja w projekt deweloperski</p>
              <p>Korzystanie z know-how doświadczonego dewelopera</p>
              <p>Wypłata kwartalna zysków</p>
              <p>Niski próg wejścia</p>
              <p>Bezobsługowość</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Worth
