import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

// import OurProjects from "components/OurProjects"
import SectionContact from "components/SectionContact"

import {
  Content,
  Baner,
  Schema,
  Process,
  // Worth,
  Why,
  BanerCta,
} from "page_components/how"

const How = () => {
  const title = "Jak to działa"

  return (
    <Layout>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      {/* <Worth /> */}
      <Content />
      <Baner />
      <Schema />
      <Process />
      <Why />
      <BanerCta />
      {/* <OurProjects data={data?.allWpPortfolio?.nodes} /> */}
      <SectionContact />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPortfolio(limit: 2, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolio {
          city
          address
          area
          shortDescription
        }
      }
    }
  }
`

export default How
