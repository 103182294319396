import "./styles.scss"

import React from "react"

import Button from "components/Button"

const BanerCta = () => {
  return (
    <section className="how-baner-cta">
      <div className="container-fluid">
        <div className="how-baner-cta__wrapper">
          <h3>Zarejestruj się i zainwestuj już dziś!</h3>
          <Button to="/projekt-15/" className="button--blue">
            zacznij inwestować
          </Button>
        </div>
      </div>
    </section>
  )
}

export default BanerCta
