import "./styles.scss"

import React, { useState } from "react"

import Calculator from "page_components/investment/Content/components/Calculator"

const Content = () => {
  const [interestRateTab, setInterestRateTab] = useState("variable")

  return (
    <section className="how-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <h3>Bezpieczna, bezobsługowa inwestycja chroniąca Twój kapitał.</h3>
            <p className="how-content__description">
              Inwestując w projekt deweloperski zabezpieczasz dwa kluczowe
              obszary związane z powierzonym nam kapitałem. Przede wszystkim
              zapewnisz swojemu kapitałowi stabilizację przy jednoczesnym
              zachowaniu wzrostu jego wartości.
            </p>
            <div className="how-content__item">
              <h5>Zysk</h5>
              <strong>
                10,61% <span>rocznie</span>
              </strong>
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            <img
              src={require("assets/images/how/how-content-image.jpg").default}
              alt=""
            />
          </div>
        </div>

        <h3>Kalkulator zysku*</h3>
        <Calculator
          interestRateTab={interestRateTab}
          setInterestRateTab={setInterestRateTab}
        />
      </div>
    </section>
  )
}

export default Content
